import * as Sentry from "@sentry/browser"

export default () => {
    if (window.sentryJavascriptDsn.length > 0) {
        console.log('sentryJavascriptDsn is empty.')
        return
    }
    Sentry.init({
        dsn: window.sentryJavascriptDsn,
        environment: window.environment,
        release: window.sentryRelease,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 0.1,
    })
}