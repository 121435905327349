import.meta.glob([
    '../images/**',
    '../fonts/**/*.{ttf,woff,woff2}',
]);

import './pwa';

import Sentry from './modules/Sentry'
Sentry()

import './modules/CConsent'

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import Lightbox from './modules/Lightbox'
import Tooltip from './modules/Tooltip'
import CardImageSlider from './modules/CardImageSlider'
import ProjectImageSlider from './modules/ProjectImageSlider'
import { refreshImages } from './modules/LaravelMedialibrary'

import Ui from '@alpinejs/ui'

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

Alpine.plugin(Ui)

import StepButtons from './modules/StepButtons.js';

Alpine.data('stepButtons', StepButtons);
Alpine.data('lightbox', Lightbox)
Alpine.directive('tooltip', Tooltip)
Alpine.data('cardImageSlider', CardImageSlider)
Alpine.data('projectImageSlider', ProjectImageSlider)

window.Alpine = Alpine

Livewire.start()

Livewire.hook('commit', ({ succeed }) => {
    succeed(() => {
        queueMicrotask(() => {
            // https://github.com/livewire/livewire/pull/7897
            queueMicrotask(() => {
                refreshImages()
            })
        })
    })
})